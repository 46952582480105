import wx from 'weixin-js-sdk';

export default {
  isInWX() {
    return new Promise((resolve, reject) => {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == 'micromessenger') {
        //ios的ua中无miniProgram，但都有MicroMessenger（表示是微信浏览器）
        wx.miniProgram.getEnv((res) => {
          if (res.miniprogram) {
            console.log('在小程序里');
            resolve(true);
          } else {
            console.log('您当前不在小程序里哦');
            resolve(false);
          }
        });
      } else {
        console.log('您当前不在小程序里哦');
        resolve(false);
      }
    });
  },
};
