import Vuex from 'vuex'
import Vue from "vue";
Vue.use(Vuex)
const store = new Vuex.Store({
    state: {
        articleId: '',
        sessionId: ''
    },
    actions: {
    },
    mutations: {
        GETARTICLEID(articleId, v) {
            this.state.articleId = v;
        },
        GETSESSIONID(sessionId, v) {
            this.state.sessionId = v;
        }
    }
})
export default store
