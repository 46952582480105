<template>
  <div>
    <div class="article-header" id="article-header">
      <div>{{ articleInfo['type'] }}</div>
      <div class="article-header-right">
        <div @click="nice">
          <img v-show="articleInfo.isNice === 1" src="../../assets/svg/bxs-liked.svg" alt="" />
          <img v-show="articleInfo.isNice !== 1" src="../../assets/svg/bx-like.svg" alt="" />
          <span>{{ articleInfo.niceNum }}</span>
        </div>
        <div @click="collect">
          <img v-show="articleInfo.isCollect === 1" src="../../assets/svg/bxs-stared.svg" alt="" />
          <img v-show="articleInfo.isCollect !== 1" src="../../assets/svg/bx-star.svg" alt="" />
          <span>{{ articleInfo.collectNum }}</span>
        </div>
        <div @click="share">
          <img v-show="articleInfo.isShare !== 1" src="../../assets/svg/bx-share1.svg" alt="" />
          <img v-show="articleInfo.isShare === 1" src="../../assets/svg/bxs-share1.svg" alt="" />
          <span>{{ articleInfo.shareNum }}</span>
        </div>
      </div>
    </div>
    <!-- 收藏文章 -->
    <van-popup
      v-model="collectShow"
      :round="true"
      :position="'bottom'"
      z-index="10000"
      @close="onCloseCollect"
      :close-on-click-overlay="true"
      :safe-area-inset-bottom="true"
    >
      <div class="collect-content">
        <div class="collect-header" @click="onCloseCollect()">取消</div>
        <div class="collect-content-top">
          <van-radio-group v-model="collectDir" @change="onChangeCollect">
            <div class="collect-dir-item" v-for="(item, index) in collectDirList" :key="index">
              <van-radio :name="item['id']">{{ item['name'] }}</van-radio>
            </div>
          </van-radio-group>
        </div>
        <div class="collect-content-bottom">
          <div class="collect-new-dir" v-if="!newDir" @click="newCollectDir()">
            <img src="../../assets/svg/plus.svg" alt="" />
            <div>新建文件夹</div>
          </div>
          <div class="collect-new-dir-do" v-if="newDir">
            <div class="collect-new-input">
              <van-cell-group>
                <van-field v-model="newDirName" maxlength="10" placeholder="最多10个字" @blur='handleBlur'/>
              </van-cell-group>
            </div>
            <div class="collect-new-creat" @click="newDirSure()">新 建</div>
          </div>
        </div>
        <div class="collect-cancel" @click="onCollect()">确定</div>
      </div>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: 'articleHeader',
  props: ['articleInfo','scrollNum'],
  data() {
    return {
      /**点赞接口正在请求 */
      niceLoading: false,
      /*************收藏开始*********************/
      /**是否显示收藏文件夹： false: 不显示； true：显示 */
      collectShow: false,
      /**文章收藏的文件夹； */
      collectDir: 1,
      /**false：文章收藏文件夹新建按钮显示；  true：新建文件夹输入框 */
      newDir: false,
      /**新建收藏夹的名字 */
      newDirName: '',
      /**收藏夹列表 */
      collectDirList: [
        { id: 1, name: '默认收藏夹' }
      ],
      /**收藏接口正在请求 */
      collectLoading: false,
      /**收藏文件夹新增接口正在请求 */
      collectDirLoading: false,
      /* 暂存当前距离顶部距离，以便键盘收回时返回到以前的位置 */
      temscrollNum: null,
      /***********************收藏结束**********************/
    };
  },
  created() {
    this.temscrollNum = this.scrollNum;
  },
  methods: {
    handleBlur() {
      window.scroll(0, this.temscrollNum);
    },
    /**
     * 点赞
     */
    nice: function () {
      if (this.niceLoading) {
        this.$toast.loading({
          message: '正在处理请求，请稍后！',
        });
        return;
      }
      this.niceLoading = true;
      this.$http
        .put(
          this.apiRootPath + '/manuscript/support',
          {
            id: this.articleInfo.id,
            type: this.articleInfo.isNice === 1 ? 2 : 1,
          },
          {
            headers: {
              Authorization: `Bearer${this.$store.state.sessionId}`,
            },
          },
        )
        .then((res) => {
          this.$toast.success(res.data.message);
          this.articleInfo.isNice = this.articleInfo.isNice === 1 ? 2 : 1;
          this.articleInfo.niceNum = res.data.object;
          this.niceLoading = false;
        })
        .catch((err) => {
          this.$toast(err.response.data.message);
          this.niceLoading = false;
          if (err.response && err.response.status == 401) {
            this.turnWxProgram(3);
          }
        });
    },
    /**
     * 收藏/取消收藏
     */
    collect() {
      if (this.collectLoading) {
        this.$toast.loading({
          message: '正在处理请求，请稍后！',
        });
        return;
      }
      /**取消收藏 */
      if (this.articleInfo.isCollect === 1) {
        this.collectLoading = true;
        this.$http
          .put(
            this.apiRootPath + '/user/book_mark/collect',
            {
              articleId: this.articleInfo.id,
              type: 2,
              dirId: '0',
            },
            {
              headers: {
                Authorization: `Bearer${this.$store.state.sessionId}`,
              },
            },
          )
          .then((res) => {
            this.$toast.success(res.data.message);
            this.articleInfo.isCollect = 2;
            this.articleInfo.collectNum = res.data.object;
            this.collectLoading = false;
          })
          .catch((err) => {
            this.$toast(err.response.data.message);
            this.collectLoading = false;
            if (err.response && err.response.status == 401) {
              this.turnWxProgram(3);
            }
          });
      } else {
        /**收藏打开 */
        this.newDir = false;
        this.loadCollectDirList();
      }
    },

    /**
     * 收藏文件夹列表
     */
    loadCollectDirList() {
      this.$http
        .get(this.apiRootPath + '/user/book_mark/list', {
          headers: {
            Authorization: `Bearer${this.$store.state.sessionId}`,
          },
        })
        .then((res) => {
          this.collectShow = true;
          this.collectDirList = res.data.items;
          this.collectDir = res.data.items[0].id;
        })
        .catch((err) => {
          this.$toast(err.response.data.message);
          if (err.response && err.response.status == 401) {
            this.turnWxProgram(3);
          }
        });
    },

    /**
     * 选择收藏文件夹
     */
    onChangeCollect(event) {
      this.collectDir = event;
    },

    /**
     * 显示新建收藏夹的输入框
     */
    newCollectDir() {
      this.newDir = true;
      this.newDirName = '';
    },

    /**
     * 新建收藏夹
     */
    newDirSure() {
      if (this.newDirName.trim().length === 0) {
        this.$toast('请先输入文件夹名称');
        return;
      }
      if (this.collectDirLoading) {
        this.$toast('正在处理请求，请稍后！');
        return;
      }
      this.collectDirLoading = true;
      this.$http
        .post(
          this.apiRootPath + '/user/book_mark/create',
          {
            dirName: this.newDirName,
          },
          {
            headers: {
              Authorization: `Bearer${this.$store.state.sessionId}`,
            },
          },
        )
        .then((res) => {
          this.$toast.success(res.data.message);
          this.collectDir = res.data.object;
          this.newDir = false;
          this.collectDirList.push({ id: res.data.object, name: this.newDirName });
          this.collectDirLoading = false;
        })
        .catch((err) => {
          this.$toast(err.response.data.message);
          this.collectDirLoading = false;
          if (err.response && err.response.status == 401) {
            this.turnWxProgram(3);
          }
        });
    },

    /**
     * 收藏
     */
    onCollect() {
      if (this.collectDirLoading || this.collectLoading) {
        this.$toast('正在处理请求，请稍后！');
        return;
      }
      this.collectLoading = true;
      this.$http
        .put(
          this.apiRootPath + '/user/book_mark/collect',
          {
            articleId: this.articleInfo.id,
            type: 1,
            dirId: this.collectDir,
          },
          {
            headers: {
              Authorization: `Bearer${this.$store.state.sessionId}`,
            },
          },
        )
        .then((res) => {
          this.$toast.success(res.data.message);
          this.articleInfo.isCollect = 1;
          this.articleInfo.collectNum = res.data.object;
          this.collectShow = false;
          this.collectLoading = false;
        })
        .catch((err) => {
          this.$toast(err.response.data.message);
          this.collectLoading = false;
          if (err.response && err.response.status == 401) {
            this.turnWxProgram(3);
          }
        });
    },

    /**
     * 关闭收藏文件夹列表
     */
    onCloseCollect() {
      this.collectShow = false;
    },
    /**
     * 分享，跳转到微信小程序分享
     */
    share: function () {
      this.$http
        .get(
          this.apiRootPath + '/user/get_user_info',
          {
            headers: {
              Authorization: `Bearer${this.$store.state.sessionId}`,
            },
          },
        )
        .then((res) => {
          this.turnWxProgram(1);
        })
        .catch((err) => {
          this.$toast(err.response.data.message);
          if (err.response && err.response.status == 401) {
            this.turnWxProgram(3);
          }
        });
    },
    /**
     * 跳转到微信小程序
     * @params {number} type
     * 1 打开普通分享
     * 2 编辑分享
     * 3 登陆框
     */
    turnWxProgram(type) {
      this.wx.miniProgram.redirectTo({
        url: `/pages/article/article-detail-mini?id=${this.articleInfo.id}&type=${type}`,
      });
    },
  },
};
</script>

<style scoped>
.article-header {
  width: 100%;
  height: 53px;
  border-top: 4px solid #ae5da1;
  background-color: #fff;
  padding: 0 15px;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  font-size: 15px;
  box-shadow: 0 2px 5px rgba(12, 3, 7, 0.17);
  color: #004986;
  z-index: 1;
}

.article-header .article-header-right {
  display: flex;
  justify-content: space-between;
}

.article-header .article-header-right div {
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 10px;
  margin-left: 20px;
}

.article-header .article-header-right div img {
  width: 24px;
}

.article-header .article-header-right div:first-of-type {
  padding-left: 0;
}

.collect-content {
  width: 100%;
  box-sizing: border-box;
  background-color: #f7f7f7;
}

.collect-content .collect-header {
  height: 75px;
  line-height: 75px;
  text-align: right;
  overflow: hidden;
  color: #576b95;
  padding-right: 30px;
}

.collect-content .collect-content-top {
  height: 175px;
  margin-bottom: 15px;
  overflow: auto;
  padding: 0 30px;
}

.collect-content .collect-content-top .collect-dir-item {
  margin-bottom: 20px;
}

.collect-content .collect-content-bottom {
  height: 65px;
  padding: 0 30px;
}

.collect-content .collect-content-bottom .collect-new-dir {
  width: 100%;
  height: 46px;
  border: 1px solid #d9d9d9;
  border-radius: 3px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
}

.collect-content .collect-content-bottom .collect-new-dir img {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}

.collect-content .collect-content-bottom .collect-new-dir-do {
  display: flex;
}

.collect-content .collect-content-bottom .collect-new-dir-do .collect-new-input {
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
  border: 1px solid #354491;
  flex-grow: 1;
  overflow: hidden;
}

.collect-content .collect-content-bottom .collect-new-dir-do .collect-new-creat {
  width: 90px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: #fff;
  background-color: #354491;
  border-top-right-radius: 3px;
  border-bottom-right-radius: 3px;
}

.collect-content .collect-cancel {
  text-align: center;
  line-height: 45px;
  background-color: #fff;
}
</style>
