import Vue from 'vue';
import App from './App.vue';
import axios from 'axios';
import mathJax from './common/js/mathJax';
import vantUI from './common/js/vantUI';
import dayjs from 'dayjs';
import Vconsole from 'vconsole';
import router from './router/index';
import store from './store';
import wx from 'weixin-js-sdk';
import VueViewer from 'v-viewer';
import VueClipBoard from 'vue-clipboard2'

Vue.use(VueViewer, {
  defaultOptions: {
    zIndex: 9999,
  },
});

Vue.config.productionTip = false;
Vue.prototype.apiRootPath = '/miniprogram';
Vue.prototype.dayjs = dayjs;
Vue.prototype.wx = wx;
Vue.prototype.$http = axios;
Vue.prototype.commonMathJax = mathJax;
Vue.use(VueClipBoard);

/**
 * 本地开发log
 */
if (process.env.NODE_ENV === 'development') {
  // new Vconsole();
}

/**
 * 拦截配置title
 */
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

/**
 * 实例化
 */
new Vue({
  render: (h) => h(App),
  router,
  store,
}).$mount('#app');





