<template>
  <div>
    <div class="save">
      <!-- 听书操作 -->
      <div :class="isvideoOpen ? 'video video-open' : 'video video-unopen'" v-if="delayShow">
        <div class="v-icon" @click="isvideoOpen ? (isvideoOpen = false) : (isvideoOpen = true)">
          <img src="@/assets/video.png" alt="videoIcon" />
        </div>
        <img
          :src="videoStatus ? require('@/assets/svg/bx-pause.svg') : require('@/assets/svg/bx-play.svg')"
          :class="videoStatus ? 'v-pause' : 'v-play'"
          @click="changeVideoStatus"
          alt="play"
        />
        <img src="@/assets/svg/x.svg" class="v-close" @click="closeVideo" alt="close" />
      </div>
    </div>
  </div>
</template>

<script>
var upLoadClicktag = 0;
export default {
  name: 'listen',
  props: ['articleInfo'],
  data() {
    return {
      videoStatus: 0, // 播放状态 0未播放 1正在播放
      isvideoOpen: false, // 展开状态
      audio: new Audio(),
      delayShow: false, // 假延迟
      pollingST: null, // 轮询定时器
    };
  },
  mounted() {
    this.$nextTick(()=> {
      if(this.articleInfo.audioUrl){
        this.listenArticle();
      }
    })
  },
  methods: {
    listenArticle() {
      let that = this;
      that.audio.src = that.articleInfo.audioUrl;
      that.audio.loop = false;
      that.videoStatus = 1;
      that.$toast.loading({
        duration: 0,
        forbidClick: true,
        message: '正在加载音频',
      });
      let playPromise;
      playPromise = that.audio.play();
      if (playPromise) {
        playPromise
          .then(() => {
            that.audio.pause();
            setTimeout(function(){
              that.isvideoOpen = false;
              that.delayShow = true;
              that.audio.play();
              that.$toast.clear();
              clearTimeout(that.pollingST)
              that.polling()
            },1000);
          })
          .catch((e) => {
            // that.$toast.fail('播放失败，自动重新加载');
            that.audio.src = that.articleInfo.audioUrl;
            that.isvideoOpen = false;
            that.delayShow = true;
            that.audio.play();
            that.$toast.clear();
            clearTimeout(that.pollingST)
            that.polling()
          });
      }
      that.audio.addEventListener('ended', function () {
        that.closeVideo()
      }, false)
    },

    closeVideo() {
      this.audio.pause();
      this.audio.load();
      clearTimeout(this.pollingST)
      this.$emit('closeListen', false);
    },

    changeVideoStatus() {
      if (upLoadClicktag === 0) {
        upLoadClicktag = 1;
        setTimeout(function () {
          upLoadClicktag = 0;
        }, 1000);
        if (this.videoStatus) {
          // 要暂停
          this.audio.pause();
          this.videoStatus = 0;
        } else {
          // 要播放
          this.audio.play();
          this.videoStatus = 1;
        }
      } else {
        this.$toast('请勿频繁操作');
      }
    },

    /**
     * 轮询告知后台当前在听时长
     */
    polling() {
      this.$http.get(this.apiRootPath + '/manuscript/statistical_duration?id=' + this.articleInfo.id + '&uuid=' + this.articleInfo.uuid).then((res) => {
        this.pollingST = setTimeout(() => {
          clearTimeout(this.pollingST)
          this.polling()
        }, 5000)
      });
    }
  },
  destroyed () {
    clearTimeout(this.pollingST)
  }
};
</script>

<style lang="scss" scoped>
.save {
  position: fixed;
  width: auto;
  height: 50px;
  bottom: 70px;
  right: 22px;
  padding-bottom: constant(safe-area-inset-bottom); // 兼容 IOS<11.2
  padding-bottom: env(safe-area-inset-bottom); // 兼容 IOS>11.2
}
.video-open {
  width: 133px;
}
.video-unopen {
  width: 50px;
}
.video {
  background: rgba(0, 0, 0, 0.7);
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  img {
    display: block;
  }
  .v-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    img {
      width: 50px;
      height: auto;
    }
  }

  .v-close {
    width: 12px;
    height: auto;
    margin-right: 20px;
  }

  .v-pause {
    width: 40px;
    height: auto;
  }

  .v-play {
    width: 36px;
    height: auto;
  }
}
</style>
