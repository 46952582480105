import axios from 'axios';
import store from '@/store';
import { Toast } from 'vant';

const service = axios.create({
  timeout: 10 * 60 * 1000,
});

service.interceptors.request.use(
  (config) => {
    config.headers = { Authorization: 'Bearer ' + store.state.sessionId };
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      // 请求超时状态
      if (error.message.includes('timeout')) {
        Toast.fail('请求超时!请检查网络是否连接正常');
      }
    }
    return Promise.reject(error);
  },
);

export default service;
