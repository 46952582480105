import dayjs from 'dayjs';

export function FormatTime(time, type) {
  // 2020年01月01日
  if (type === 'ch-ymd') {
    let month = dayjs(time).month() + 1 < 10 ? '0' + (dayjs(time).month() + 1) : dayjs(time).month() + 1;
    let day = dayjs(time).date() < 10 ? '0' + dayjs(time).date() : dayjs(time).date();
    return dayjs(time).year() + '-' + month + '-' + day;
  }
  // 16 June 2022
  if (type === 'en-ymd') {
    let month = dayjs(time).month() + 1;
    let day = dayjs(time).date() < 10 ? '0' + dayjs(time).date() : dayjs(time).date();
    let enMonthList = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    return day + ' ' + enMonthList[month] + ' ' + dayjs(time).year();
  }
}

/**
 * 仿 jq 获取位置信息
 * @param element
 * @returns {{top: number, left: number}}
 * @constructor
 */
export function offset(element) {
  const offest = {
    top: 0,
    left: 0,
  };
  let _position;
  getOffset(element, true);

  return offest;

  // 递归获取 offset, 可以考虑使用 getBoundingClientRect
  function getOffset(node, init) {
    // 非Element 终止递归
    if (node.nodeType !== 1) {
      return;
    }
    _position = window.getComputedStyle(node)['position'];

    // position=static: 继续递归父节点
    if (typeof init === 'undefined' && _position === 'static') {
      getOffset(node.parentNode);
      return;
    }
    offest.top = node.offsetTop + offest.top - node.scrollTop;
    offest.left = node.offsetLeft + offest.left - node.scrollLeft;

    // position = fixed: 获取值后退出递归
    if (_position === 'fixed') {
      return;
    }

    getOffset(node.parentNode);
  }
}

/**
 * html 转义
 * @param str
 * @returns {*}
 */
export function escape2Html(str) {
  const arrEntities = { lt: '<', gt: '>', nbsp: ' ', amp: '&', quot: '"' };
  return str.replace(/&(lt|gt|nbsp|amp|quot);/gi, function(all, t) {
    return arrEntities[t];
  });
}

export function delATag(str) {
  return str.replace(/(<\/?a.*?>)/g, '');
}
