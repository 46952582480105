<template>
  <div>
    <van-dialog v-model="copyLinkShow" className="copy" :showConfirmButton="false">
      <template slot="default">
        <p class="c-t">链接已生成，复制到浏览器下载</p>
        <p class="c-p" v-html='downloadLink'></p>
        <button
          v-clipboard:copy="downloadLink"
          v-clipboard:success="copySuccess"
          v-clipboard:error="copyError">
          复制链接
        </button>
      </template>
    </van-dialog>
  </div>
</template>

<script>
let listenUrl
export default {
  name: 'downloadtips',
  data() {
    return {
      copyLinkShow: false,
      downloadLink: '',
    };
  },
  created() {
    this.copyLinkShow= true;
    // 解决ios转码问题
    document.addEventListener("copy",listenUrl = ()=> {
      if(event.clipboardData || event.originalEvent){
        var clipboardData = (event.clipboardData || event.originalEvent.clipboardData);
        clipboardData.setData('text/plain',this.downloadLink.toString());
        event.preventDefault();
      }
    })
    this.madeUrl()
  },
  methods: {
    madeUrl() {
      const herf = window.location.origin + '/#/download';
      this.downloadLink = `${herf}?id=&title=${encodeURIComponent(this.$route.query.title)}&viewpath=${encodeURIComponent(this.$route.query.viewpath)}`;
    },

    copySuccess(e) {
      this.$toast.success('复制成功');
    },

    copyError(e) {
      this.$toast.fail('复制失败');
    },
  },
  destroyed() {
    window.removeEventListener("copy",listenUrl);
  },
};
</script>

<style lang="scss" scoped>
.copy {
  padding: 20px 15px 20px;
  box-sizing: border-box;
  & .c-t {
    color: #555;
    font-size: 14px;
    margin: 0 0 8px;
  }
  & .c-p {
    max-height: 100px;
    overflow-y: auto;
    color: #aaa;
    font-size: 15px;
    margin: 0 auto 30px;
    padding: 5px 10px;
    border-radius: 6px;
    background: #f1f1f1;
    word-wrap: break-word;
    line-height: 22px;
  }
  & button {
    display: block;
    width: 100%;
    margin: 0 auto;
    background: #556fb5;
    border: 0;
    color: #fff;
    font-size: 15px;
    line-height: 40px;
    border-radius: 50px;
  }
}
</style>
