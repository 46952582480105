<template>
  <div>
    <van-tabbar z-index="9999" v-model="active" active-color="#61707b" inactive-color="#61707b" safe-area-inset-bottom>
      <van-tabbar-item v-for="(item, index) in tabList" :key="index" @click="onChange()">
        <img slot="icon" :src="item.icon" class="tab-img" alt="" />
        {{ item.name }}
      </van-tabbar-item>
    </van-tabbar>
    <!-- 更多 -->
    <van-popup v-model="moreShow" round position="bottom" z-index="99" :close-on-popstate="false" safe-area-inset-bottom>
      <footer-more @moreHandleClick="moreHandleClick"></footer-more>
    </van-popup>
    <!-- 更多 by导出引文类型 -->
    <van-action-sheet
      v-model="downloadShow"
      :actions="actions"
      cancel-text="取消"
      description="请选择你要下载的文件类型"
      close-on-click-action
      @select="exportCiteFile"
    />
    <!-- 更多 by导出引文类型 by下载（复制链接）提示框 -->
    <van-dialog v-model="copyLinkShow" className="copy" :showConfirmButton="false">
      <template slot="default">
        <p class="c-t">链接已生成，复制到浏览器下载</p>
        <p class="c-p">{{ downloadLink }}</p>
        <button
            v-clipboard:copy="downloadLink"
            v-clipboard:success="copySuccess"
            v-clipboard:error="copyError">
          复制链接
        </button>
      </template>
    </van-dialog>
  </div>
</template>

<script>
import footerMore from './component/footerMore';
let listenUrl
export default {
  name: 'articleFooter',
  components: { footerMore },
  props: ['articleInfo', 'articleId', 'sessionId', 'authorInfo'],
  data() {
    return {
      tabList: [
        { name: '目录', icon: require('../../assets/png/menu-catalogue.png') },
        { name: '作者', icon: require('../../assets/png/menu-user.png') },
        { name: '听原文', icon: require('../../assets/png/menu-listen.png') },
        { name: '下载', icon: require('../../assets/png/menu-download.png') },
        { name: '更多', icon: require('../../assets/png/menu-more.png') },
      ],
      actions: [
        { name: 'EndNote(RIS)', id: 2 },
        { name: 'BibTeX', id: 3 },
        { name: 'NoteExpress', id: 4 },
        { name: 'Refworks', id: 5 },
        { name: 'GB/T 7714-2015', id: 1 },
      ],
      moreShow: false,
      downloadShow: false,
      copyLinkShow: false,
      downloadLink: '',
      active: 0,
    };
  },
  created() {
    // 解决ios转码问题
    document.addEventListener("copy",listenUrl = ()=> {
      if(event.clipboardData || event.originalEvent){
        var clipboardData = (event.clipboardData || event.originalEvent.clipboardData);
        clipboardData.setData('text/plain',this.downloadLink.toString());
        event.preventDefault();
      }
    })
  },
  methods: {
    takeBack() {
      this.moreShow = false;
      this.downloadShow = false;
      this.$emit('open-set', false);
      this.$emit('catalogShow', false);
      this.$emit('authorShow', false);
    },

    onChange() {
      this.takeBack();
      if (this.active === 0) {
        // 目录
        this.$emit('catalogShow', true);
      } else if (this.active === 1) {
        // 作者
        this.$emit('authorShow', true);
      } else if (this.active === 2) {
        // 听原文
        this.$emit('listenArticle', true);
      } else if (this.active === 3) {
        // 下载
        this.wx.miniProgram.navigateTo({
          url: '/pages/article/pdf-download?articleId=' + this.articleId + '&articleTitle=' + this.articleInfo.title,
        });
      } else {
        // 更多
        this.moreShow = true;
      }
    },

    moreHandleClick(i) {
      this.takeBack();
      if (i.id === 0) {
        this.$http
          .get(
            this.apiRootPath + '/user/get_user_info',
            {
              headers: {
                Authorization: `Bearer${this.$store.state.sessionId}`,
              },
            },
          )
          .then((res) => {
            // 编辑分享
            this.turnWxProgram(2);
          })
          .catch((err) => {
            this.$toast(err.response.data.message);
            if (err.response && err.response.status == 401) {
              this.turnWxProgram(3);
            }
          });
      } else if (i.id === 1) {
        // 查看ESM
        this.wx.miniProgram.navigateTo({ url: `/pages/article/article-esm?articleId=${this.articleId}` });
      } else if (i.id === 2) {
        // 导出引文
        this.downloadShow = true;
      } else {
        // 设置
        this.$emit('open-set', true);
      }
    },

    /**
     * 跳转到微信小程序
     * @params {number} type
     * 1 打开普通分享
     * 2 编辑分享
     * 3 登陆框
     */
    turnWxProgram(type) {
      this.wx.miniProgram.redirectTo({
        url: `/pages/article/article-detail-mini?id=${this.articleInfo.id}&type=${type}`,
      });
    },

    exportCiteFile(id) {
      this.$toast.loading({
        message: '生成链接中,请稍后',
        forbidClick: true,
        duration: 0,
      });
      const flag = id.id;
      let title = '';
      if (flag === 1) {
        title = 'gb_t_7714-2015.txt';
      } else if (flag === 2) {
        title = 'endnote_ris.txt';
      } else if (flag === 3) {
        title = 'bibtex.txt';
      } else if (flag === 4) {
        title = 'noteexpress.txt';
      } else if (flag === 5) {
        title = 'refworks.txt';
      }
      this.$toast.clear();
      this.copyLinkShow = true;
      const herf = window.location.origin + '/#/download';
      this.downloadLink = `${herf}?id=${this.articleId}&title=${title}&viewpath=`;
    },

    copySuccess(e) {
      this.copyLinkShow = false;
      this.$toast.success('复制成功');
    },

    copyError(e) {
      this.copyLinkShow = false;
      this.$toast.fail('复制失败');
    },
  },
  destroyed() {
    window.removeEventListener("copy",listenUrl);
  },
};
</script>

<style scoped lang="scss">
.footer-o {
  z-index: 999999;
  background: rgba(225, 225, 225, 0.6);
  .van-loading {
    text-align: center;
    top: 250px;
  }
}
.tab-img {
  margin-bottom: 2px;
}

.copy {
  padding: 20px 15px 20px;
  box-sizing: border-box;
  & .c-t {
    color: #555;
    font-size: 14px;
    margin: 0 0 8px;
  }
  & .c-p {
    color: #aaa;
    font-size: 15px;
    margin: 0 auto 30px;
    padding: 5px 10px;
    border-radius: 6px;
    background: #f1f1f1;
    word-wrap: break-word;
    overflow-y: auto;
    line-height: 22px;
  }
  & button {
    display: block;
    width: 100%;
    margin: 0 auto;
    background: #556fb5;
    border: 0;
    color: #fff;
    font-size: 15px;
    line-height: 40px;
    border-radius: 50px;
  }
}
.video-open {
  width: 133px;
}
.video-unopen {
  width: 50px;
}
.video {
  position: fixed;
  bottom: 70px;
  right: 22px;
  background: rgba(0, 0, 0, 0.7);
  height: 50px;
  border-radius: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
  img {
    display: block;
  }
  .v-icon {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    img {
      width: 50px;
      height: auto;
    }
  }
  .v-close {
    width: 12px;
    height: auto;
    margin-right: 20px;
  }
  .v-pause {
    width: 40px;
    height: auto;
  }
  .v-play {
    width: 36px;
    height: auto;
  }
}
</style>
