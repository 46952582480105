import { bus } from '@/common/js/bus';
import { delATag, escape2Html, offset } from '@/common/util/util';
import ArticleHeader from './article-header';
import ArticleFooter from './article-footer';
import CatalogPanel from './component/catalogPanel';
import listenPanel from './component/listenPanel';
import footerAuthor from './component/footerAuthor';
import 'viewerjs/dist/viewer.css';
import { api as viewerApi } from 'v-viewer';

export default {
  name: 'index',
  data() {
    return {
      articleId: this.$route.query.articleId,
      jsId: this.$route.query.jsId,
      // articleId: '1533642278549811201', // 文章id
      // jsId: '273df2d9-f983-4356-ab32-60adef387cce',
      scrollTop: 0,
      whetherShow: true,
      authorInfo: { authors: [] },
      articleInfo: {}, // 文章基本信息
      contentList: [], // 文章正文内容
      catalogList: [], // 文章章节列表
      shareImageList: [], // 正文图片列表
      articleReference: [], // 参考文献列表
      activeNames: [''],
      /*************设置参数 *************/
      setShow: false, // 设置弹框： false： 不显示； true：显示；
      /**文章背景索引 */
      articleBg: 0,
      /**文章字体大小索引 */
      articleFs: 2,
      /**文章字体索引 */
      articleFont: 0,
      /**文章字体大小列表 */
      articleFsList: [14, 15, 16, 17, 18, 19, 20],
      /**文章背景列表/字体颜色列表 */
      articleBgcList: [
        {
          bg: '#f0f5f8',
          color: '#333333',
        },
        {
          bg: '#e0b789',
          color: '#333333',
        },
        {
          bg: '#c3e4c4',
          color: '#333333',
        },
        {
          bg: '#161616',
          color: '#9194A3',
        },
      ],
      /**文章字体列表 */
      articleFontList: [
        {
          name: '默认',
          font: '',
          url: '',
        },
        {
          name: '思源黑',
          font: 'SourceHanSansCN-Regular',
          url: process.env.VUE_APP_ARTICLE_SIZE_URL + 'public/font/syht-Regular.otf',
        },
        {
          name: '思源宋',
          font: 'SourceHanSerifCN-Regular',
          url: process.env.VUE_APP_ARTICLE_SIZE_URL + 'public/font/syst-Regular.otf',
        },
        {
          name: '仓耳三',
          font: 'cesy-003',
          url: process.env.VUE_APP_ARTICLE_SIZE_URL + 'public/font/cesy-003.ttf',
        },
      ],
      /*************设置参数结束 *************/
      catalogShow: false,
      authorShow: false,
      /**是否阻止事件执行 */
      prevenEvent: false,
      /* 听书写操作 */
      ifvideoShow: false,
      startY: null,
      endY: null,
      move: false,
      isFirstClick: false, // 记录点击听书操作，每次只记录一次
    };
  },
  components: { CatalogPanel, ArticleHeader, ArticleFooter, listenPanel, footerAuthor },
  created() {
    this.$store.commit('GETARTICLEID', this.articleId);
    this.$store.commit('GETSESSIONID', this.jsId);
    bus.$on('expandChange', (res) => this.expandChange(res));
    bus.$on('jump', (res) => this.jump(res));
    this.getBasicInfo();
    this.getAbout();
    this.getReference();
    /**
     * 代理 html 方法
     * @type {default}
     * @private
     */
    let _this = this;
    window.findReferencesById = _this.findReferencesById;
    window.showImage = _this.showImage;
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('touchstart', this.touchstart);
    window.addEventListener('touchmove', this.touchmove);
    window.addEventListener('touchend', this.touchend);
  },
  methods: {
    touchstart(event) {
      this.move = false;
      this.startY = event.changedTouches[0].pageY;
    },
    touchmove() {
      this.move = true;
    },
    touchend(event) {
      this.endY = event.changedTouches[0].pageY;
      if (this.move) { // 只有当move为true时才会触发滑动事件
        if (this.endY - this.startY > 0) { // 结束y坐标 - 开始坐标 > 0 向上翻页 < 0向下翻页
          this.whetherShow = this.scrollTop < 56;
        } else {
          this.whetherShow = this.scrollTop < 56;
        }
      }
    },

    // 加载文章基本信息
    getBasicInfo() {
      this.$http
        .get(this.apiRootPath + '/manuscript/basicInfo?articleId=' + this.articleId, {
          headers: {
            Authorization: `Bearer ${this.$store.state.sessionId}`,
          },
        })
        .then((res) => {
          this.articleInfo = res.data.object;
        });
    },

    // 加载文章正文内容、大纲信息、图片列表
    getContent() {
      this.$http
        .get(this.apiRootPath + '/manuscript/content?articleId=' + this.articleId, {
          headers: {
            Authorization: `Bearer ${this.$store.state.sessionId}`,
          },
        })
        .then((res) => {
          if (res.data.object.contentLists) {
            this.contentList = res.data.object.contentLists;
          }
          if (res.data.object.catalogList) {
            let catalogArr = res.data.object.catalogList;
            if (res.data.object.catalogList.length > 0) {
              const findNode = (nodes, key) => {
                for (const node of nodes) {
                  if (node.level !== 1) {
                    node.parentKey = key;
                  }
                  if (node.children.length) {
                    key = node.key;
                    findNode(node.children, key);
                  }
                }
              };
              findNode(catalogArr);
              catalogArr.push({
                children: [],
                isLeaf: true,
                key: 'article-reference',
                level: 1,
                title: `References（${this.articleReference.length}）`,
              });
            }
            this.catalogList = catalogArr;
          }
          if (res.data.object.imageLists) {
            this.shareImageList = res.data.object.imageLists;
            this.shareImageList.forEach(
                (v) => (v.url = escape2Html(/<img [^>]*src=['"]([^'"]+)[^>]*>/gi.exec(v.url)[1])),
            );
          }

          // 处理公式渲染；
          this.$nextTick(function() {
            if (this.commonMathJax.isMathJaxConfig) {
              this.commonMathJax.initMathJaxConfig();
            }
            this.commonMathJax.MathQueue('article_content');
          });
        });
    },

    // 加载作者、about信息
    getAbout() {
      this.$http.get(this.apiRootPath + '/manuscript/about_this?articleId=' + this.articleId).then((res) => {
        this.authorInfo = res.data.object;
      });
    },

    // 加载参考文献
    getReference() {
      this.$http
        .get(this.apiRootPath + '/manuscript/reference?articleId=' + this.articleId, {
          headers: {
            Authorization: `Bearer ${this.$store.state.sessionId}`,
          },
        })
        .then((res) => {
          res.data.items.forEach((v) => {
            v.content = delATag(v.content);
          });
          this.articleReference = res.data.items;
          this.getContent();
        });
    },

    /** ======================================设置======================================== **/

    // 打开/关闭设置页面
    openSet(flag) {
      this.setShow = flag;
    },

    // 设置字体
    setFontFamily(index) {
      this.$toast.loading({
        message: '设置中,请稍后',
        forbidClick: true,
        duration: 0,
      });
      if (this.articleFontList[index].font) {
        /**加载字体 */
        const font = new FontFace(this.articleFontList[index].font, `url("${this.articleFontList[index].url}")`);
        font
          .load()
          .then((res) => {
            this.toastMsg(1);
            document.fonts.add(font);
            this.onCloseSet();
          })
          .catch(() => {
            this.toastMsg(0);
          });
      } else {
        this.toastMsg(1);
      }
      this.articleFont = index;
    },

    // 设置字体提示关闭  flag 0失败 1成功
    toastMsg(flag) {
      this.$toast.clear();
      if (flag) {
        this.$toast.success('设置成功!');
      } else {
        this.$toast.success('设置失败!');
      }
    },

    // 设置字体大小
    setFontSize(flag) {
      if (flag == 1) {
        if (this.articleFs == 0) {
          return;
        }
        this.articleFs = --this.articleFs;
      } else {
        if (this.articleFs == 6) {
          return;
        }
        this.articleFs = ++this.articleFs;
      }
    },

    // 设置背景颜色/字体颜色
    setBgColor(index) {
      this.articleBg = index;
    },

    // 关闭设置
    onCloseSet() {
      this.setShow = false;
    },

    /** ======================================设置结束======================================== **/

    handleHide() {
      if (this.scrollTop >= 56 && !this.prevenEvent) {
        this.whetherShow ? (this.whetherShow = false) : (this.whetherShow = true);
      }
    },

    handleScroll(event) {
      if (this.prevenEvent) {
        return;
      }
      let canvas = this.$refs.act.childNodes;
      this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      for (const canva of canvas) {
        if (canva.offsetTop > this.scrollTop && canva.id) {
          const node = this.getTreeNodeByKey(canva.id, this.catalogList, true);
          this.$set(node, 'selected', true);
          // 寻找父级并展开
          const handleNode = (node, nodes) => {
            this.$set(node, 'expand', true);
            if (node.parentKey) {
              handleNode(this.getTreeNodeByKey(node.parentKey, nodes), nodes);
            }
          };
          handleNode(node, this.catalogList);
          break;
        }
      }
    },

    expandChange(item) {
      const node = this.getTreeNodeByKey(item.key, this.catalogList);
      this.$set(node, 'expand', !item.expand);
    },

    // 根据 key 找到 node, reset 是否重置选中和展开状态
    getTreeNodeByKey(key, nodes, reset) {
      let _node;
      const findNode = (key, nodes) => {
        for (const node of nodes) {
          if (reset) {
            this.$set(node, 'expand', false);
            this.$set(node, 'selected', false);
          }
          if (node.key === key) {
            _node = node;
            // 如果不重置，则无需循环所有，直接跳出循环
            if (!reset) {
              break;
            }
          }
          if (node.children.length) {
            findNode(key, node.children);
          }
        }
      };
      findNode(key, nodes);
      return _node;
    },

    jump(item) {
      const findNode = (nodes) => {
        for (const node of nodes) {
          this.$set(node, 'selected', false);
          if (node.children.length) {
            findNode(node.children);
          }
        }
      };
      findNode(this.catalogList);
      let time = 0;
      if (item.key === 'article-reference') {
        time = 500;
        this.activeNames = ['reference'];
      }
      this.$set(item, 'selected', true);
      const top = document.getElementById(item.key).offsetTop;
      setTimeout(() => {
        this.jumpPos(top);
        this.catalogShow = false;
        this.whetherShow = true;
      }, time);
    },

    // 参考文献跳转详情
    jumpContent(item) {
      const xref = document.querySelectorAll(`xref[class='${item.id}']`);
      const top = xref[0].offsetTop;
      this.jumpPos(top);
    },

    jumpPos(top) {
      // const headerHeight = document.getElementById('article-header').getBoundingClientRect().height;
      const headerHeight = 0;
      window.scrollTo({ top: top - headerHeight - 10, behavior: 'smooth' });
    },

    findReferencesById(event) {
      const rid = event.currentTarget.getAttribute('rid');
      this.activeNames = ['reference'];
      setTimeout(() => {
        const top = offset(document.getElementById(rid)).top;
        this.jumpPos(top);
      }, 500);
    },

    showImage(event) {
      viewerApi({
        options: {
          toolbar: true,
          url: 'url',
          initialViewIndex: this.shareImageList.findIndex((v) => v.id === event.id),
        },
        images: this.shareImageList,
      });
    },

    turnRelation() {
    },

    // 听书操作
    listenBegin(v) {
      this.$http
        .get(this.apiRootPath + '/manuscript/audio_url?articleId=' + this.articleId, {
          headers: {
            Authorization: `Bearer ${this.$store.state.sessionId}`,
          },
        })
        .then((res) => {
          this.articleInfo.audioUrl = res.data.object;
          if (this.articleInfo.audioUrl) {
            this.ifvideoShow = v;
            // 记录听书次数
            if (!this.isFirstClick) {
              this.$http.get(this.apiRootPath + '/manuscript/enhance_stat_add?id=' + this.articleId).then((res) => {
                this.isFirstClick = true;
              });
            }
          } else {
            this.ifvideoShow = false;
            // ja阶段没有音频文件
            if (this.articleInfo.stage === 2) {
              this.$toast('暂无资源');
            } else {
              this.$toast('正在转码，时间较长，请稍后操作');
            }
          }
        });
    },

    // 关闭听书操作
    closeListen(v) {
      this.ifvideoShow = v;
    },

    // 跳转至微信期刊页面 type: 1跳转学者主页 2跳转期刊
    goToWx(v, type) {
      if (type === 1) {
        if (v.id) {
          this.wx.miniProgram.reLaunch({
            url: `/pages/find/scholar-library/scholar-detail?id=${v.id}`,
          });
        }
      } else {
        this.wx.miniProgram.reLaunch({
          url: `/pages/journal/journal-home/journal-home?journalId=${this.articleInfo.journalId}`,
        });
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
    window.removeEventListener('touchstart', this.touchstart);
    window.removeEventListener('touchmove', this.touchmove);
    window.removeEventListener('touchend', this.touchend);
    bus.$off(['jump', 'expandChange']);
  },
};
