import Vue from 'vue';
import {
  Button,
  Tabbar,
  TabbarItem,
  Sticky,
  Toast,
  Popup,
  Icon,
  RadioGroup,
  Radio,
  Cell,
  CellGroup,
  Field,
  Loading,
  Grid,
  GridItem,
  Collapse,
  CollapseItem,
  ActionSheet,
  Overlay,
  Dialog
} from 'vant';

Vue.use(Collapse);
Vue.use(CollapseItem);
Vue.use(Button);
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Sticky);
Vue.use(Toast);
Vue.use(Popup);

Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(Field);
Vue.use(Icon);
Vue.use(Loading);
Vue.use(Grid);
Vue.use(GridItem);
Vue.use(ActionSheet);
Vue.use(Overlay);
Vue.use(Dialog);

