<template>
  <div>
    <template v-if='catalogList.length > 0'>
      <div class="panel-item" v-for="item in catalogList" :key="item.key">
        <div class="item-info" @click="emits('jump', item)">
          <div class="title" v-bind:class="{ selected: item.selected }" v-html="item.title"></div>
          <van-icon
            v-if="!item.isLeaf"
            @click.stop="emits('expandChange', item)"
            :name="item.expand ? 'arrow-up' : 'arrow-down'"
          />
        </div>
        <template v-if="item.children.length && item.expand">
          <CatalogPanel :article-info="articleInfo" :catalog-list="item.children" />
        </template>
      </div>
    </template>
    <template v-else>
      <div class='content-empty'>No data</div>
    </template>
  </div>
</template>
<script>
import { bus } from '../../../common/js/bus';
export default {
  name: 'CatalogPanel',
  props: {
    articleInfo: {},
    catalogList: Array,
  },
  methods: {
    emits(emit, item) {
      bus.$emit(emit, item);
    },
  },
};
</script>
<style scoped lang="scss">
.content-empty {
  margin: 50px 0 0;
  text-align: center;
}
.panel-box {
  .panel-item {
    display: flex;
    flex-direction: column;
    margin-top: 5px;
    .panel-item {
      margin-left: 10px;
    }
    .item-info {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      min-height: 30px;
      align-items: center;
      .title {
        display: flex;
        align-items: center;
        &.selected {
          color: #354491;
        }
      }
    }
  }
}
</style>
