import Vue from 'vue'
import VueRouter from 'vue-router'
import Index from '@/pages/article/index.vue'
import Download from '@/pages/download/index.vue'
import DownloadTips from '@/pages/download-tip/index.vue'
Vue.use(VueRouter);

const routes = [
    { path: '/', name: 'index', component: Index, meta: { title: '文章详情' } },
    { path: '/index', name: 'index', component: Index, meta: { title: '文章详情' } },
    { path: '/download', name: 'download', component: Download, meta: { title: '下载附件' } },
    { path: '/downloadtips', name: 'downloadtips', component: DownloadTips, meta: { title: '下载附件' } }
];

export default new VueRouter({
    // mode: 'history',
    routes // (缩写) 相当于 routes: routes
});

