<template>
  <div class="more">
    <div class="tab flex">
      <div v-for="item in tabs" :key="item.id" @click="handleClick(item)" class="flex">
        <p :style="{ background: item.color }" class="flex">
          <img :src="item.src" alt="" :class='item.class'/>
        </p>
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'more',
  props: ['articleId'],
  data() {
    return {
      tabs: [
        { title: '编辑分享', src: require('@/assets/svg/bx-share-alt.svg'), color: '#80c269', id: 0 },
        { title: '查看ESM', src: require('@/assets/svg/bx-copy-alt.svg'), color: '#ff835e', id: 1 },
        { title: '导出引文', src: require('@/assets/svg/bx-export.svg'), color: '#97c1fa', id: 2 },
        { title: '设置', src: require('@/assets/png/bx-setting.png'), color: '#4fcced', id: 3 , class: 'img-width' },
      ],
    };
  },
  created() {},
  methods: {
    // 操作
    handleClick(i) {
      this.$emit('moreHandleClick', i);
    },
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.more {
  padding: 35px 0 72px;
  background: #f7f7f7;
  .tab {
    padding: 0 15px;
    box-sizing: border-box;
    justify-content: space-around;
    div {
      flex-direction: column;
      p {
        width: 58px;
        height: 58px;
        border-radius: 50px;
        margin: 0 0 8px 0;
        img {
          width: 28px;
          height: 28px;
        }
        .img-width{
          width: 24px !important;
          height: auto !important;
        }
      }
      span {
        font-size: 14px;
      }
    }
  }
}
</style>
