<template>
  <div class="author">
    <van-loading v-if="loading" type="spinner" color="#556FB5" class="loading" />
    <template v-else>
      <div class="author-box" v-if='articleInfo.stage !== 2'>
        <p class="author-title author-title-no">Author</p>
        <div class="author-list">
          <span v-for="(item, index) in authorInfo['authors']" @click="toAuthor(item)" :key="index">
            <!-- 名称 -->
            <span :style="{ color: item.id ? '#556FB5' : '' }">{{ item.name }}</span>
            <!-- unit数组 -->
            <span v-for="(it, i) in item.unit" :key="i" :style="{ color: item.haveEmail === 1 ? '#556FB5' : '' }" class="author-unit">
               {{ it }}{{ item.unit.length === i + 1 ? '' : ',' }}
            </span>
            <!-- id -->
            <span v-if="item.haveOrcid === 1" class="author-orcid">iD</span>
            <!-- 邮箱 -->
            <span v-if="item.haveEmail === 1" style="color: #556fb5">(<van-icon name="envelop-o" />)</span>
            <span v-if="index + 1 !== authorInfo.authors.length" class="author-end">;</span>
          </span>
        </div>
        <div class="author-co">
          <p v-for="(item, index) in authorInfo['authorsUnit']" :key="index">
            <span v-html="item['title']"></span>
          </p>
        </div>
      </div>
      <div class="author-box">
        <p class="author-title" :style="{ border: articleInfo.stage === 2 ? 0 : '' }">Publication history</p>
        <div class="author-pub">
          <p v-if="authorInfo['pubHistory']['received']">Received: {{ handleFormatTime(authorInfo['pubHistory']['received']) }}</p>
          <p v-if="authorInfo['pubHistory']['revised']">Revised: {{ handleFormatTime(authorInfo['pubHistory']['revised']) }}</p>
          <p v-if="authorInfo['pubHistory']['accepted']">Accepted: {{ handleFormatTime(authorInfo['pubHistory']['accepted']) }}</p>
          <p v-if="authorInfo['pubHistory']['onlinePublished']">{{ articleInfo.stage === 2 ? 'Available online' : 'Published' }}: {{ handleFormatTime(authorInfo['pubHistory']['onlinePublished']) }}</p>
        </div>
      </div>
      <div v-if="authorInfo['copyright']" class="author-box" >
        <p class="author-title">Copyright</p>
        <div class="author-cr">
          <p v-html="authorInfo['copyright']"></p>
        </div>
      </div>
      <div v-if="authorInfo['rightsAndPermissions']" class="author-box">
        <p class="author-title">Rights and permissions</p>
        <div class="author-pm">
          <p>
            <span class="author-pm-m" v-html="authorInfo['rightsAndPermissions']"></span>
          </p>
<!--          <p>-->
<!--            <span>Reprints and Permission requests may be sought directly from editorial office.</span><br />-->
<!--            <span v-if="articleInfo['serviceAddress']" style="font-weight: bold">Email: {{-->
<!--              articleInfo['serviceAddress']-->
<!--            }}</span>-->
<!--          </p>-->
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { FormatTime } from '@/common/util/util';
export default {
  name: 'author',
  props: ['articleInfo', 'authorInfo'],
  data() {
    return {
      loading: true,
    };
  },
  watch: {
    authorInfo(n,o) {
      this.authorInfo = n
    }
  },
  created() {
    this.loading = !this.authorInfo;
  },
  methods: {
    // 格式化时间
    handleFormatTime(time) {
      return FormatTime(time, 'en-ymd');
    },
    // 跳转【小程序】作者主页
    toAuthor(v) {
      if (v.id) {
        this.wx.miniProgram.reLaunch({
          url: `/pages/find/scholar-library/scholar-detail?id=${v.id}`,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$lineHeight: 24px;
$color: #444;

p {
  margin: 0;
}
.author {
  color: $color;
  padding: 15px 15px;
  box-sizing: border-box;
  .author-box {
    margin-bottom: 20px;
    .author-title {
      font-weight: bold;
      color: #000;
      padding: 20px 0 10px 0;
      border-top: 1px solid #d9d9d9;
      box-sizing: border-box;
    }
    .author-title-no {
      border: 0;
      padding-top: 10px;
    }
    .author-list {
      .author-unit {
        vertical-align: super;
        font-size: small;
      }
      .author-orcid {
        width: 32px;
        height: 32px;
        display: inline-block;
        background-color: #a6ce39;
        border-radius: 50%;
        text-align: center;
        line-height: 32px;
        color: #fff;
        font-size: x-small;
      }
      .author-end {
        padding-right: 20px;
      }
      /deep/ .van-icon {
        top: 3px;
      }
    }
    .author-co {
      p {
        margin: 10px 0;
        span {
          line-height: $lineHeight;
        }
      }
    }
    .author-pub {
      p {
        margin: 3px 0;
      }
    }
    .author-cr {
      p {
        line-height: $lineHeight;
      }
    }
    .author-pm {
      span {
        line-height: $lineHeight;
      }
      .author-pm-m {
        margin-bottom: 15px;
        word-break: break-word;
      }
    }
  }
}
</style>
