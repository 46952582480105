<template>
  <div id="app">
    <div v-if="pass">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import native from '@/common/util/native';

export default {
  name: 'App',
  data() {
    return {
      pass: true,
    };
  },
  created() {
    // 页面权限校验
    // this.authen();
  },
  methods: {
    authen() {
      const exceptPath = ['/download'];
      const noneedAuth = exceptPath.map((p) => p === this.$route.path)[0];
      if (noneedAuth) {
        // 下载页等页面，无需判断
        this.pass = true;
      } else {
        native.isInWX().then((flag) => {
          if (flag) {
            // 当前环境是小程序
            this.pass = true;
            console.log('识别到了小程序');
          } else {
            console.log('没有识别到小程序');
            this.pass = false;
            // 当前环境不是小程序【后期可叠加环境判断，如app的唯一标识(隐藏在app端useragent，需app原生提供)等】
            this.$toast.fail({
              message: '暂无权限访问',
              forbidClick: true,
              duration: 0,
            });
          }
        });
      }
    },
  },
};
</script>

<style>
#app {
  width: 100%;
  height: 100%;
  font-size: 16px;
  background: #f0f5f8;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>
