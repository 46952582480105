<template>
  <div class="downLoad"></div>
</template>

<script>
import request from '../../common/util/request';

export default {
  name: 'download',
  data() {
    return {
      id: this.$route.query.id, // 文章id
      title: this.$route.query.title, // 附件的名称
      viewpath: this.$route.query.viewpath, // 附件的完整路径
      articleInfo: [
        { name: 'gb_t_7714-2015.txt', flag: '1' },
        { name: 'endnote_ris.txt', flag: '2' },
        { name: 'bibtex.txt', flag: '3' },
        { name: 'noteexpress.txt', flag: '4' },
        { name: 'refworks.txt', flag: '5' },
      ],
    };
  },
  created() {
    this.$nextTick(function () {
      this.judge();
    });
  },
  methods: {
    /**
     * 情况1：需调接口下载
     * 情况2：打开完整浏览地址的附件
     */
    judge() {
      if (this.viewpath && !this.id) {
        this.toOpenFile();
      } else {
        this.toDownFile();
      }
    },

    toOpenFile() {
      this.handleDown(this.viewpath, this.title)
    },

    toDownFile() {
      let needsFlag = '';
      this.articleInfo.forEach((item) => {
        if (item.name === this.title) {
          needsFlag = item.flag;
        }
      });
      request
        .get(this.apiRootPath + '/manuscript/export/ris?id=' + this.id + '&flag=' + needsFlag)
        .then((res) => {
          // 转为 Blob
          let blob = new Blob([res.data]);
          let objectUrl = URL.createObjectURL(blob);
          this.handleDown(objectUrl, this.title)
        })
        .catch((err) => {
          this.$toast.fail('下载失败');
        });
    },

    handleDown(url, name) {
      let a = document.createElement('a');
      a.setAttribute('href', url);
      a.setAttribute('download', name);
      a.click();
      a.remove()
    }
  },
};
</script>

<style lang="scss" scoped>
.downLoad {
  background: #fff;
  padding: 15px 20px;
}
</style>
